/* eslint-disable space-before-function-paren */
import Vue from 'vue'
// import { localCache } from '@/common/storage'
// import { UPDATE_TOKEN } from '../../config/index'
// import { router } from '../../routes/router'
import * as types from '../action-type'
import { newApi } from '@/config'

const actions = {
    [types.GET_USER_REGISTER_ORDERS]({ commit, dispatch }, params) { // 查询用户的会议订单 2.0
        return new Promise((resolve, reject) => {
            Vue.req.get('user/meeting/orders', {
                params,
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.CREATE_REGISTER_ORDER]({ commit, dispatch }, data) { // 创建报名订单 2.0
        return new Promise((resolve, reject) => {
            Vue.req.post('enroll', data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.GET_REGISTER_ORDER_DETAIL]({ commit, dispatch }, id) { // 获取报名详情 2.0
        return new Promise((resolve, reject) => {
            Vue.req.get(`meeting/orders/${id}`, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.APPLY_ORDER_PAY]({ commit, dispatch }, data) { // 申请支付 2.0
        return new Promise((resolve, reject) => {
            Vue.req.post(`order/${data.id}/pay`, data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.ORDER_CANCEL]({ commit, dispatch }, data) { // 取消订单 2.0
        return new Promise((resolve, reject) => {
            Vue.req.post(`order/${data.id}/cancel`, data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.ORDER_DELETE]({ commit, dispatch }, data) { // 删除订单 2.0
        return new Promise((resolve, reject) => {
            Vue.req.post(`order/${data.id}/delete`, data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.APPLY_REFUND]({ commit, dispatch }, data) { // 退款申请 2.0
        return new Promise((resolve, reject) => {
            Vue.req.post('order/refund', data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.APPLY_REFUND_CANCEL]({ commit, dispatch }, data) { // 取消退款申请 2.0
        return new Promise((resolve, reject) => {
            Vue.req.post(`order/${data.id}/cancel/refund`, data, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.GET_HOTEL_ORDER]({ commit, dispatch }, params) { // 获取酒店订单 2.0
        return new Promise((resolve, reject) => {
            Vue.req.get('user/hotel/orders', {
                params,
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.GET_HOTEL_ORDER_DETAIL]({ commit, dispatch }, id) { // 获取酒店订单 2.0
        return new Promise((resolve, reject) => {
            Vue.req.get(`user/hotel/orders/${id}`, {
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    [types.GET_ROOM_ALLOCATION]({ commit, dispatch }, params) { // 获取分房订单 2.0
        return new Promise((resolve, reject) => {
            Vue.req.get('user/roomAllocation/orders', {
                params,
                baseURL: newApi()
            }).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export default {
  actions
}
