<template>
  <div class="view-box-transition">
    <div class="meeting" :style="style">
      <div style="min-height: calc(100vh - 50px);position: relative;padding-bottom: 2.72rem;">
        <van-notice-bar
          v-if="notice"
          left-icon="volume"
          :text="notice"
          color="#999999"
          background="#F2FCFF"
        />
        <div class='banner'>
          <van-swipe :autoplay="3000" class="banner-item">
            <van-swipe-item v-for="(image, index) in bannerList" :key="index">
              <van-image :src="image.banner" class="banner-item" fit="cover"/>
            </van-swipe-item>
          </van-swipe>
          <div class="meeting-countDown" v-if="this.timeDiff">
            <p>会议开始倒计时：{{ timeDiff }}</p>
          </div>
        </div>
        <Layout8 :menus="showMenus" v-if="pageLayout === 3"></Layout8>
        <Layout7 :menus="showMenus" v-if="pageLayout === 2"></Layout7>
        <Layout9 :menus="showMenus" v-if="pageLayout === 1"></Layout9>
        <van-grid v-else-if="pageLayout === 0" class="menu-list" :border="false" :column-num="3" :gutter="'0.43rem'" square>
          <van-grid-item
            class="menu"
            @click="detail(item)"
            v-for="(item, index) in showMenus"
            :key="index"
          >
            <van-image :src="item.pageIcon" class="menu-item" radius="4" fit="cover"/>
            <div class="menu-item-name" :style="{color: subPageTitleFontColor}">{{ item.pageName }}</div>
          </van-grid-item>
        </van-grid>

        <footer class="footer">
          <div class="footer-host">
            <a :href="fixedMeetingCopyrightLink" :style="{ color: this.meetingCopyrightColor }">{{ this.meetingCopyright }}</a>
          </div>
          <div class="footer-tips">
            <div class="footer-logo" @click="toHuiXie"></div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>

import { getUrlParam } from '@/common/myUtil'
import { mapActions } from 'vuex'
import { TOKEN_KEY, getToken, removeCookie } from '@/common/auth'
import { localCache } from '@/common/storage'
import { share } from '@/config/wxApi'
// import wxConfig from '@/config/wxConfig'
import Layout7 from '@/views/meeting/components/7-1.vue'
import Layout8 from '@/views/meeting/components/8-1.vue'
import Layout9 from '@/views/meeting/components/9-1.vue'
import jwtDecode from 'jsonwebtoken'

export default {
  name: 'meeting',
  components: {
    Layout8, Layout7, Layout9
  },
  data () {
    return {
      logo: '',
      bannerList: [],
      backgroundImg: '',
      menus: [],
      pageLayout: 0,
      style: {},
      meetingId: null,
      timeDiff: '',
      notice: '',
      meetingCopyright: '',
      meetingCopyrightLink: '',
      meetingCopyrightColor: '',
      subPageTitleFontColor: 'rgba(172, 177, 205, 1)',
      subPageTitleBgColor: ''
    }
  },
  computed: {
    fixedMeetingCopyrightLink () {
      if (this.meetingCopyrightLink && (this.meetingCopyrightLink.startsWith('http://') || this.meetingCopyrightLink.startsWith('https://'))) {
        return this.meetingCopyrightLink
      } else if (this.meetingCopyrightLink) {
        return 'http://' + this.meetingCopyrightLink
      } else {
        return 'javascript:;'
      }
    },
    showMenus () {
        if (this.$store.state.app.hideLabors || !getToken()) { // 无劳务费 或 未登录 不显示菜单
            const menus = this.menus.filter(item => ((!item.pageType || JSON.parse(item.pageType).type !== 19) && item.isDisplay))
            window.localStorage.setItem('navList', JSON.stringify(menus))
            return menus
        } else {
            return this.menus.filter(item => item.isDisplay)
        }
    }
  },
  activated () {
  },
  created () {
    this.logPageAccess()
    // if (window.__is_weixin) {
    //   window.onload = () => {
    //     wxConfig().then(() => {
    //       console.log('wx sdk')
    //     })
    //   }
    // }
    this.meetingId = getUrlParam('meetingId') || 0
    localCache.set('meetingId', this.meetingId)
    this.$loading.show()
    this.geMeeting(this.meetingId).then(res => {
      if (res) {
        this.logo = res.meetingLogo
        if (res.meetingBanners.length > 0) {
          this.bannerList.push(...res.meetingBanners)
        } else if (res.meetingBanner) {
          this.bannerList.push({ banner: res.meetingBanner })
        }
        this.backgroundImg = res.meetingBg
        this.pageLayout = res.pageLayout
        this.getDays(res.startAt)
        this.notice = res.meetingNotice
        this.meetingCopyright = res.meetingCopyright
        this.meetingCopyrightLink = res.meetingCopyrightLink
        this.meetingCopyrightColor = res.meetingCopyrightColor
        this.subPageTitleFontColor = res.subPageTitleFontColor
        if (res.meetingSubpages.length > 0) {
          this.$store.commit('setTabbarStatus', true)
          this.menus = res.meetingSubpages.map(item => {
            // if (item.pageDisplayMode === 3) { // 定制页面
            //   const pageType = JSON.parse(item.pageType)
            //   if (pageType.isLogin) { // 需要登录 则显示tabbar
            //     this.$store.commit('setTabbarStatus', true)
            //   }
            // }
            return {
              pageIcon: item.pageIcon,
              pageName: item.pageName,
              pageContent: item.pageContent,
              pageLink: item.pageLink,
              isDisplay: item.isDisplay,
              // pageBg: item.pageBg,
              pageBg: res.subpageTitleBgColor || '#FFFFFF',
              pageColor: res.subPageTitleFontColor || '#000000',
              meetingId: this.meetingId,
              pageDisplayMode: item.pageDisplayMode,
              pageId: item.meetingSubPageId,
              pageType: item.pageType
            }
          })
          window.localStorage.setItem('navList', JSON.stringify(this.menus))
        }
        this.style = { background: 'url(' + this.backgroundImg + ') no-repeat center / cover', height: '100%' }
        if (res.meetingName) {
            document.title = res.meetingName
            localCache.set('meetingName', res.meetingName)
        } else {
            localCache.remove('meetingName')
        }
        this.setOptions(res)
        // 当网格布局时设置背景色
        if (res.pageLayout === 0) {
          this.$nextTick(() => {
            const elements = document.querySelectorAll('.van-grid-item__content')
            elements.forEach(element => {
              element.style.backgroundColor = res.subpageTitleBgColor || 'rgba(255, 255, 255, 0.9)'
            })
          })
        }
      } else {
        this.$router.push({
          path: '/404',
          query: {
            type: 1
          }
        })
      }
    }).catch(err => {
      this.$toast.fail('获取会议信息失败')
      console.log(err)
      this.$router.push({
        path: '/404',
        query: {
          type: 1
        }
      })
    }).finally(() => {
      this.$loading.hide()
    })
    const token = getToken()
    if (token) {
        // const info = jwtDecode.decode(token)
        // if (info.UserStatus * 1 !== 1) { // 审核未通过 更新token
        //     this.updateToken(this.meetingId).then(res => {
        //     }).catch(() => {
        //         this.$toast('系统异常，联系管理员')
        //     })
        // }
        // this.getLaborsInfo(this.meetingId).then(res => { // 验证是否为有劳务费的嘉宾
        //     if (res) {
        //         this.hideLabors = false
        //     }
        // })
    }
  },
  methods: {
    ...mapActions({
      geMeeting: 'GET_MEETING',
      updateToken: 'UPDATE_TOKEN' // 更新token
    }),
    detail (item) {
      if ([1, 2].includes(item.pageDisplayMode)) {
        this.$router.push({
          path: '/detail',
          query: {
            pageId: item.pageId,
            meetingId: item.meetingId
          }
        })
      } else if (item.pageDisplayMode === 3) { // 定制页面
        const token = getToken()
        let info = null
        if (token) {
          info = jwtDecode.decode(token)
        }
        const pageType = JSON.parse(item.pageType)
        if (!pageType.isLogin) { // 不需要登录
          // if (pageType.path === '/register' && info && Number(this.meetingId) === Number(info.meetingId || 0)) { // 如果登录成功 则进入个人中心页面
          //     this.$store.commit('setTabValue', 1)
          //     this.$router.push('/center')
          // } else
          this.$router.push({
            path: `${pageType.path}`,
            query: {
              pageId: item.pageId,
              meetingId: item.meetingId
            }
          })
        } else {
          if (token && Number(this.meetingId) === Number(info.meetingId || 0)) {
            this.$router.push({
              path: `${pageType.path}`,
              query: {
                pageId: item.pageId,
                meetingId: item.meetingId
              }
            })
          } else {
            removeCookie(TOKEN_KEY)
            this.$router.push({
              path: `/login?redirect=${encodeURIComponent(pageType.path)}`,
              query: {
                pageId: item.pageId,
                meetingId: item.meetingId
              }
            })
          }
        }
      } else if (item.pageDisplayMode === 0) {
        const a = document.createElement('a')
        if (item.pageLink.includes('http')) {
          a.href = item.pageLink
        } else {
          a.href = 'https://' + item.pageLink
        }
        a.target = '_blank'
        a.click()
        // window.location.href = item.pageLink
      }
    },
    setOptions (data) {
      const option = {
        title: data.shareTitle,
        desc: data.shareDesc,
        link: `${window.location.href}`,
        imgUrl: data.shareIcon || data.meetingBanner,
        meetingId: this.meetingId
      }
      share(option)
    },
    getDays (startTime) {
      const now = new Date()
      const timeDiff = Date.parse(startTime) - now.getTime() // 时间戳之差（毫秒）
      if (timeDiff / (1000 * 60 * 60 * 24) > 1) {
        this.timeDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + ' 天' // 天数
      } else if (timeDiff / (1000 * 60 * 60 * 24) > 0) {
        this.timeDiff = Math.floor(timeDiff / (1000 * 60 * 60)) + ' 小时' // 小时
      }
    },
    toHuiXie () {
        window.location.href = 'https://www.huixie.wang'
    }
  }
}
</script>

<style scoped lang="scss">
.meeting {
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 12px;

  &::-webkit-scrollbar {
    display: none;
  }

  /deep/ .van-notice-bar__left-icon {
    color: rgba(67, 196, 190, 1);
  }
}

.logo {
  width: 100%;
  margin-top: 80px;

  .logo-item {
    width: 100%;
  }
}

.banner {
  height: 5.63rem;
  overflow: hidden;
  width: 100%;
  position: relative;

  ::v-deep .van-swipe__indicators {
    bottom: 0.1rem;

    .van-swipe__indicator--active {
      background-color: #43c4be;
    }
  }

  .banner-item {
    width: 100%;
    height: 100%;
  }

  .meeting-countDown {
    width: 5.07rem;
    height: 0.64rem;
    text-align: center;
    line-height: 0.64rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.19rem;
    position: absolute;
    left: 2.48rem;
    bottom: 0.13rem;
    font-size: 0.37rem;
    font-weight: 500;
    color: #FFFFFF;
  }
}

.menu-list {
  width: 100%;
  padding-top: 0.43rem;
  position: relative;

  /deep/ .van-grid-item__content {
    padding: 0;
    border-radius: 0.53rem;
    overflow: hidden;
  }
}

.menu-item {
  width: 1.6rem;
  height: 1.6rem;
}

.menu-item-name {
  font-size: 0.37rem;
  margin-top: 6px;
}

.addressinfo {
  margin-top: 20px;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 15px;
    margin-bottom: 5px;
  }
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0.99rem;
  font-size: 0.35rem;

  .footer-host {
    text-align: center;
    margin-bottom: 0.32rem;

    a {
      color: #fff;
      height: 0.45rem;
      text-decoration: underline;
    }
  }

  .footer-tips {
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-logo {
      width: 1.76rem;
      height: 0.45rem;
      border-radius: 0.05rem;
      background: url(~@/assets/images/footer-logo.png) no-repeat center center / contain;
    }
  }
}
</style>
